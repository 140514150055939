import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import {
  TextField
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect} from "react-redux";
import * as authDuck from '../../../../store/ducks/auth.duck';
import axios from "axios";
import { Config } from "../../../../config/config";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

const headRows = [
  { id: 'title', numeric: 'center', visibility:true,disablePadding: false, label: 'Title'},
  { id: 'date_start', numeric: 'center', visibility:true,disablePadding: false, label: 'Start Date' },
  { id: 'date_end', numeric: 'center', visibility:true,disablePadding: false, label: 'End Date' },
  { id: 'total_time', numeric: 'center', visibility:true,disablePadding: false, label: 'Allocated Time' },
  { id: 'spent_time', numeric: 'center', visibility:true,disablePadding: false, label: 'Spent Time' },
  { id: 'status', numeric: 'center', visibility:true,disablePadding: false, label: 'Status' },
  { id: 'action', numeric: 'center', visibility:true,disablePadding: false, label: 'Action' },

];

function prependNumber(single_digit) {
 
  let prepended_out =
      String(single_digit).padStart(2, '0');
  return prepended_out;

}

const mapStateToProps = (state) => ({
  user:state.auth.user,
})
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(0),

    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function CurrentNurseWorking(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const jid = queryParams.get('id');
  const history = useHistory();
  const classes = useStyles();
  const { numSelected,selected } = props;
  const [allinfo,setAllinfo] = React.useState([]);


  if(jid==='' || jid === 'null' || jid === null || jid === undefined){
    history.push("/myprofile/current-working");
  }
  

  useEffect(() => {
    fetchData();
  }, [])

  async function fetchData(){
    await getJobData()
    await get_tasks()
  }

  async function getJobData() {
    let response = await axios.post(`${Config.api_url}job/single-job`,{'jobId':jid}).then(res => { 
        
      if(res['data']['job']['client']!=props.user._id){
        history.push("/myprofile/current-working");
      }
    })
    return response;

  }

  async function get_tasks(){
    await fetch(`${Config.api_url}job/getTasks`,{
      method:'POST',
      headers:{
      'Content-Type': 'application/json' },
      body :JSON.stringify({ jobId: jid})
      }
    ).then(res=>{
      res.json().then(data=>{
        setAllinfo(data.tasks)
        
      })

    }).catch(err=>{
        console.log('error:',err);
    })
  }
  async function handleStatus(id,status,jid,client){
    if(status == 0){
      await axios.post(`${Config.api_url}job/updatetaskstatus`,{'_id':id,'job_id':jid,'client':client}).then(res => { 
       
        if(res['data']['task']!==null){
          setAllinfo(res['data']['tasks'])
        }
      })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
               Task List
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <Tooltip title="New Task">
                  <Link to={"/myprofile/add-task?id="+jid}><IconButton aria-label="AddCircleOutlineIcon">
                    <AddCircleOutlineIcon />
                  </IconButton></Link>
                </Tooltip>
            </div>
          </Toolbar>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
                <TableHead>
                  <TableRow>
                      {headRows.map((row,index) => (
                        <TableCell
                          key={row.id}
                          align={row.numeric}
                          padding={row.disablePadding ? 'none' : 'default'}
                          >
                            {row.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {((allinfo.length<=0)? <TableRow><TableCell colSpan={7} align="center">No Data Found</TableCell></TableRow>:"")}


                { allinfo.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;


                  var date_start = row.startDate;
                  if (typeof date_start !== "undefined") {
                    var objectDate2 = new Date(date_start);
                    var objectDate2Arr = objectDate2
                      .toLocaleDateString()
                      .split("/");

                      date_start =
                      objectDate2Arr[0] +
                      "/" +
                      objectDate2Arr[1] +
                      "/" +
                      objectDate2Arr[2];
                  } else {
                    date_start = "";
                  }


                  var date_end = row.endDate;
                  if (typeof date_end !== "undefined") {
                    var objectDate2 = new Date(date_end);
                    var objectDate2Arr = objectDate2
                      .toLocaleDateString()
                      .split("/");

                      date_end =
                      objectDate2Arr[0] +
                      "/" +
                      objectDate2Arr[1] +
                      "/" +
                      objectDate2Arr[2];
                  } else {
                    date_end = "";
                  }
                  
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        
                        <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                          {row.title}
                        </TableCell>
                        <TableCell align="center">{date_start}</TableCell>
                        <TableCell align="center">{date_end}</TableCell>
                        <TableCell align="center">{ prependNumber(row.TotalHrs)} H { prependNumber(row.TotalMins)} M { prependNumber(row.TotalSec)} S</TableCell>
                        <TableCell align="center">{ prependNumber(row.SpentHrs)} H { prependNumber(row.SpentMins)} M { prependNumber(row.SpentSec)} S</TableCell>
                        <TableCell align="center"> 
                        <Button variant="contained" color={(row.status==0)?'secondary':'default'}><Link  onClick={(e)=>{ e.preventDefault(); handleStatus(row._id,row.status,jid,props.user._id) }}>{row.status==0?(' Approve Spent Time '):'Approved'}</Link></Button>

                      </TableCell>
                        <TableCell align="center"><Tooltip title="Edit Task">
                          <Link to={"/myprofile/add-task?id="+jid+"&task_id="+row._id} >
                            <IconButton aria-label="EditIcon"  style={{paddingTop:0,paddingBottom:0}}>
                            <EditIcon />
                            </IconButton> 
                          </Link>
                          </Tooltip>
                         
                        </TableCell>
                      </TableRow>
                    );
                  

                })}
                </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

export default connect(
  mapStateToProps,
  {...authDuck.actions}
)(CurrentNurseWorking)