/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect} from "react";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import { connect} from "react-redux";
import * as actions from '../../app/actions';
import * as userDuck from "../../app/store/ducks/user.duck";
import * as jobDuck from "../../app/store/ducks/job.duck";
import * as categoryDuck from '../../app/store/ducks/category.duck';
// import PortletHeaderDropdown from "../partials/content/CustomDropdowns/PortletHeaderDropdown";
import axios from "axios";
import { Config } from "../config/config";
function BestSellers(props) {
  const [allJobinfo,setAllJobinfo] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [clients, setClients] = React.useState([]);

  useEffect(() => {
    let CategoryList = new Array();
      props.allcategories.map((row, index) => {
      CategoryList[row._id] = row.name;
    });
    setCategories(CategoryList);

    let ClientList = new Array();
    props.clients.map((row, index) => {
      ClientList[row._id] = row.firstName + ' '+ row.lastName;;
    });
    setClients(ClientList);
    getJobData()


  }, [props])

  async function getJobData() {
    let response = await axios.post(`${Config.api_url}job/get`).then(res => {   
    var arr = res['data']['jobs'];
    if(typeof arr !== "undefined"){
      arr = arr.sort((a, b) => b.count - a.count)
      // setAllJobinfo(arr);

    }  
  
  });
   
    return response;
  }
  // console.log(allJobinfo,'allJobinfo');

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Best Jobs</h3>
          </div>
          {/* <PortletHeaderDropdown /> */}
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget5">
              {allJobinfo.slice(0,3).map((item) =>
                <div className="kt-widget5__item ">
                <div className="kt-widget5__content">
                  <div className="kt-widget5__pic">
                    <img
                      alt=""
                      className="kt-widget7__img"
                      src={toAbsoluteUrl("/media/work.png")}
                    />
                  </div>
                  <div className="kt-widget5__section">
                    <a className="kt-widget5__title">{item.title}</a>
                    <p className="kt-widget5__desc">{categories[item.category]}</p>
                    <div className="kt-widget5__info">
                      <span>Author: </span>
                      <span className="kt-font-info">{item.client ? clients[item.client] : 'Undefined'}</span>
                      <span>Created: </span>
                      {/* <span className="kt-font-info">{item.modifyDate.toLocaleDateString()}</span> */}
                      <span className="kt-font-info">{item.modifyDate}</span>
                    </div>
                  </div>
                </div>
                <div className="kt-widget5__content">
                  <div className="kt-widget5__stats">
                  <span className="kt-widget5__number">{item.budget}</span>
                    <span className="kt-widget5__sales">Budget</span>
                  </div>
                  <div className="kt-widget5__stats">
                    <span className="kt-widget5__number">{item.count}</span>
                    <span className="kt-widget5__votes">Bids</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  jobs: state.job.jobs,
  allcategories: state.category.allcategories,
  clients: state.user.clients,
})
export default connect(
  mapStateToProps,
  {...jobDuck.actions,...categoryDuck.actions,...userDuck.actions}
)(BestSellers)
