import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import swal from 'sweetalert';
import { Alert } from "react-bootstrap";
import clsx from 'clsx';

import * as actions from '../../../../actions';
import * as authDuck from '../../../../store/ducks/auth.duck';
import {useHistory , Link } from 'react-router-dom';
import axios from "axios";
import { Config } from "../../../../config/config";

function CurrentEdit(props) {

  const queryParams = new URLSearchParams(window.location.search);
  const jid = queryParams.get('id');
  const task_id = queryParams.get('task_id');

  const [status, setStatus] = useState('');
  const [id, setId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [title, setTitle] = useState('');
  const [totalhrs, setTotalHrs] = useState(0);
  const [totalMins, setTotalMins] = useState(0);
  const [totalSec, setTotalSec] = useState(0);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [summary, setSummary] = useState('');

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });


  if(jid==='' || jid === 'null' || jid === undefined){
    history.push("/myprofile/current-working");
  }



  useEffect(() => {
    getJobData()
  }, [])

  async function getJobData() {

    let response = await axios.post(`${Config.api_url}job/single-job`,{'jobId':jid}).then(res => { 
        
    if(res['data']['job']['client']!=props.user._id){
        history.push("/myprofile/current-working");
      }
    })

    if(task_id !=='' && task_id !== undefined){
      await axios.post(`${Config.api_url}job/single-task`,{'_id':task_id,'job_id':jid}).then(res => { 
        if(res['status'] == "false"){
          history.push("/myprofile/current-working");
        }
        if(res['data']['task']!==null){
          setId(task_id)

          setTitle(res['data']['task']['title'])
          setSummary(res['data']['task']['summary'])
          var date_start = res['data']['task']['startDate'];
          if (typeof date_start !== "undefined") {
            var objectDate2 = new Date(date_start);
            var objectDate2Arr = objectDate2
              .toLocaleDateString()
              .split("/");

              date_start =
              objectDate2Arr[2] +
              "-" +
              objectDate2Arr[1] +
              "-" +
              objectDate2Arr[0];
          } else {
            date_start = "";
          }

          setStartDate(date_start)

          var date_end = res['data']['task']['endDate'];
          if (typeof date_end !== "undefined") {
            var objectDate2 = new Date(date_end);
            var objectDate2Arr = objectDate2
              .toLocaleDateString()
              .split("/");

              date_end =
              objectDate2Arr[2] +
              "-" +
              objectDate2Arr[1] +
              "-" +
              objectDate2Arr[0];
          } else {
            date_end = "";
          }
          setEndDate(date_end)
          setTotalHrs(res['data']['task']['TotalHrs'])
          setTotalMins(res['data']['task']['TotalMins'])
          setTotalSec(res['data']['task']['TotalSec'])

        }
      })
    }

   


    return response;

  }

  const save = () => {

     if(title === '' || summary === '' || startDate === '' || endDate === '' ){
      setStatus('You have to input all the correctly info')
      return;
    }

    if(totalMins ==0 && totalSec ==0 && totalhrs ==0 ){
      setStatus('Please add time')
      return;
    }

    if(totalMins > 59 || totalSec > 59 ){
      setStatus('Invalid Time Format')
      return;
    }

    var tempData = {
      title: title,
      summary:summary,
      startDate:startDate,
      endDate:endDate,
      TotalHrs:totalhrs,
      TotalMins:totalMins,
      TotalSec:totalSec,
      jobId:jid,
      job:jid
    };
    
    if(id!=='' && id!=null){
      tempData._id = id;
      handleUpdate(tempData)

    }else{
      handleSave(tempData)

    }

  }
  const  handleSave=(result)=>{
     axios.post(`${Config.api_url}job/addtask`,result).then(res => { 
        history.push(`/myprofile/job-tasks?id=${jid}`);
  
    })
    

   
  }

  const  handleUpdate=(result)=>{
    axios.post(`${Config.api_url}job/updatetask`,result).then(res => { 
       history.push(`/myprofile/job-tasks?id=${jid}`);
   })
   

  
 }

  return (
    <div className="row" style={{backgroundColor: "white", padding: "40px 20px 40px 20px"}}>
      <div className="col-md-2">
      </div>
      <div className="col-md-8">
        <div className="col-md-12">
          <span className="col-md-6 cold-sm-12" style={{fontSize: "1.275rem", fontWeight: 600 }}>{id===''?'New':'Edit'} Task</span>
          <div className="col-md-6 col-sm-12 pull-right">
            <Link to={"/myprofile/job-tasks?id="+jid}><button className="btn btn-secondary pull-right">Cancel</button></Link>
            
              {id===''?
              <button className="btn btn-primary pull-right" onClick={save} style={{marginRight: 10, }}> Add </button>
               :
              <button style={{marginRight: 10, }} onClick={save} className={`btn btn-primary pull-right ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}>
                      {/* {id===''?'Add':'Update'} */}
                      {'Update'}
              </button> 
             }

          </div>
        </div>




        <div className="col-md-12" style={{marginTop: 60}}>
        {status !=='' ?<div className="col-md-12">
              <Alert variant='danger'>
                  {status}
              </Alert>
          </div>:<div></div>}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Task Title</label>
            <div className="col-lg-9 col-xl-6">
              <input className="form-control" type="text" value={title} onChange={ (e) => setTitle(e.target.value) }/>
            </div>
          </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Task Summary</label>
              <div className="col-lg-9 col-xl-6">
                <textarea className="form-control" rows="4"value={summary} onChange={ (e) => setSummary(e.target.value) }></textarea>
              </div>
            </div>

            <div className="form-group  row">
                <label className="col-xl-3 col-lg-3 col-form-label">Start Date</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group">
                    <input type="date" className="form-control" value={startDate} onChange={ (e) => setStartDate(e.target.value) }/>
                    <div className="input-group-append"></div>
                  </div>
                </div>
            </div>
            <div className="form-group  row">
                <label className="col-xl-3 col-lg-3 col-form-label">End Date</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group">
                    <input type="date" className="form-control" value={endDate} onChange={ (e) => setEndDate(e.target.value) }/>
                    <div className="input-group-append"></div>
                  </div>
                </div>
            </div>
            <div className="form-group  row times">
                <label className="col-xl-3 col-lg-3 col-form-label">Total Time</label>
                <div className="col-xl-3 col-lg-3">
                  <div className="input-group">
                    <input type="number" min="0" step="1" pattern="[0-9]" placeholder="Hours" className="form-control" value={totalhrs} onChange={ (e) => setTotalHrs(e.target.value) }/>
                    <div className="input-group-append">Hours</div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 ">
                  <div className="input-group">
                    <input type="number" min="0" max="59" step="1" pattern="[0-9]" placeholder="Mins" className="form-control" value={totalMins} onChange={ (e) => setTotalMins(e.target.value) }/>
                    <div className="input-group-append">Mins</div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <div className="input-group">
                    <input type="number" min="0" max="59" step="1" pattern="[0-9]" placeholder="Mins" className="form-control" value={totalSec} onChange={ (e) => setTotalSec(e.target.value) }/>
                    <div className="input-group-append">Sec</div>
                  </div>
                </div>
            </div>
            
        </div>
      </div>

                  
                    
 


    </div>

    
  );
}

const mapStateToProps = (state) => ({
  user:state.auth.user,
})

export default connect(
    mapStateToProps,
    {...authDuck.actions}
)(CurrentEdit);
