import React , { useEffect, useState }from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../nurse/layout/Header";
import Footer from "../../nurse/layout/Footer";
import { connect} from "react-redux";
import './Jobs.scss'
import BreadCrumb from "../layout/BreadCrumb";
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from './../../../app/pages/home/MySnackBar';
import {
    TextField,
  } from "@material-ui/core";
import {
    makeStyles,
} from "@material-ui/core/styles";
  import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as actions from '../../../app/actions';
import * as authDuck from '../../../app/store/ducks/auth.duck';
import * as activityDuck from '../../../app/store/ducks/activity.duck';
import * as categoryDuck from '../../../app/store/ducks/category.duck';
import * as userDuck from '../../../app/store/ducks/user.duck';
import * as jobDuck from '../../../app/store/ducks/job.duck';
import * as bidDuck from '../../../app/store/ducks/bid.duck';
import default_img from './../../assets/users/work.png';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import urlserver from '../../../../src/app/config/urlserver';
import api_key from '../../../../src/app/config/api_key';

import bacImage from "../../assets/jobset.jpeg";

const marks1 = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '5',
    },
  ];
const marks = [
    {
      value: 0,
      label: '0 $',
    },
    {
      value: 100,
      label: '10000 $',
    },
  ];
  function valuetext(value) {
    return `${value} $`;
  }
  function valueLabelFormat(value) {
    return value * 100    ;
  }
  function valueLabelFormat1(value) {
    return value / 20    ;
  }
function Jobs(props) {
    const history = useHistory();

    if(typeof props.user.permission === 'undefined') {
        history.push('/home')
    }


    if(props.user){
        console.log('jobs notifications working');
        actions.getAllNotifications(props.user._id)
        .then(res => {
            console.log('certnifionca ation')
          let {data} = res;
          if(!data.success) {
            props.allNotifications([]);
          } else {
            props.allNotifications(data.notifications);
          }
        })
        .catch((err) => {
        });
      }
      else {
        console.log('jobs notifications not working');
      }
    
    const [addsnack, setAddsnack] = React.useState(false);
    const [advanceflag,setAdvanceflag] = React.useState(false);
  const [allcategory, setAllcategory]= React.useState([]);
  const [minValue, setMinValue]= React.useState(0);
  const [subText, setSubText]= React.useState('');
  const [filterCategory, setFilterCategory] = React.useState('All Category');
//   const [filterLocation, setFilterLocation] = React.useState('All Locations');
  const [filterRangeBudget, setFilterRangeBudget] = React.useState([0,100]);
  const [filterReview, setFilterReview] = React.useState([0,0]);
  const [filterMiles, setFilterMiles] = React.useState('Miles');
  const [alljobs, setAlljobs]= React.useState([]);
  const [selected, setSelected] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [values, setValues] = React.useState({
        name: 'Cat in the Hat',
        age: '',
        multiline: 'Controlled',
        currency: 'EUR',
      });
    const filterBtn = () =>{
          var obj={};
          if(subText !== '')
              Object.assign(obj, {subText:subText})
          Object.assign(obj, {'category':filterCategory});
        //   Object.assign(obj, {'location':filterLocation});
          Object.assign(obj, {'review':1});
          Object.assign(obj, {'budget':1});
          setSelected(obj)
        //   setAlljobs(props.jobs || []);
        var tempArr = props.jobs;
        console.log('1)')
        console.log(tempArr)
        tempArr = tempArr.filter(sub=>{
            // console.log('sub')
            // console.log(sub)
            // console.log(obj['subText']) 
            // console.log(sub.title.indexOf(obj['subText']))
            if(sub.status != 'In Progress')
            return false;
            if(obj['subText'] && sub.title.indexOf(obj['subText'])=== -1)
                return false;
            if(filterCategory !== 'All Category' && sub.category._id !== filterCategory)
                return false;
            if(filterRangeBudget[1] * 100 < sub.budget && filterRangeBudget[0]*100 > sub.budget)
                return false;
            if(props.user){
                var subTempArr = props.bids;
                    subTempArr = subTempArr.filter(sub1=>{
                            if(sub1.job._id === sub._id && sub1.nurse._id === props.user._id)
                            return true;
                            return false;
                        })
                    if(subTempArr[0])return false;     
            }
         
            return true;
            // if(filterLocation !== 'All Locations' && sub.location !== filterLocation)
            //     return false;
            
        });
        // console.log('2)')
        // console.log(tempArr)
            setAlljobs(tempArr)
            console.log('-- filter --')
            console.log(props.jobs)
            // console.log(alljobs)
      }


      const filterNurse=()=>{
        const filReview=[filterReview[0] / 20,filterReview[1] / 20];
        const filRangeBudge=[filterRangeBudget[0]*100,filterRangeBudget[1]*100];
        var zipcode=props.user.zipCode;
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+zipcode+'&key='+api_key,{
            method:"POST"
        })
        .then(response => response.json())
        .then(data => {
            getPostJob(data,subText,filterCategory,filReview,filRangeBudge,filterMiles);
        })


    }


function getlocations(zipcode){
    // console.log('zipcode',zipcode);
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+zipcode+'&key='+api_key,{
            method:"POST"
            // headers:{'Content-type':'application/json'}
        })
        .then(response => response.json())
        .then(data => {
            getPostJob(data);
        })
    }
   

    function getPostJob(data,subText,filterCategory,filReview,filRangeBudge,filterMiles){
        fetch(`${urlserver}getAllJobs_kris`,{
            method:"POST",
            headers:{'Content-type':'application/json'},
            body:JSON.stringify({
                data:data,
                subText:subText,
                filterCategory:filterCategory,
                filReview:filReview,
                filRangeBudge:filRangeBudge,
                filterMiles:filterMiles
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success){
               console.log(data.jobs);
            setAlljobs(data.jobs || []);
            }else{
                setAlljobs([]);
            }
        
        })
    
    }



    const useStyles = makeStyles(theme => ({
        container: {
          display: "flex",
          flexWrap: "wrap"
        },
        textField1:{
            marginLeft:'5px',
            marginRight:'5px'
        },
        textField: {
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(0),
          marginTop: '8px',
          marginBottom: '8px',
        },
        dense: {
          marginTop: theme.spacing(2)
        },
        menu: {
          width: 200
        },
        termps_check:{
          marginRight:'3px'
        }
      }));
      const removeOneSelect = key =>{
          var obj= selected;
          delete obj[key];
          handleClearAll();
        //   setSelected(obj);
      }
      const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
      };
      const handleClearAll = ()=>{
          setSelected(null);
          setFilterCategory('All Category')
        //   setFilterLocation('All Locations')
        setFilterMiles('Miles')
          setFilterRangeBudget([0,100])
          setFilterReview([0,100])
          setSubText('')
          var tempArr = props.jobs;
          tempArr = tempArr.filter(sub=>{
            if(sub.status != 'In Progress')
                return false;
                if(props.user){
                    var subTempArr = props.bids;
                        subTempArr = subTempArr.filter(sub1=>{
                                if(sub1.job._id === sub._id && sub1.nurse._id === props.user._id)
                                return true;
                                return false;
                            })
                        if(subTempArr[0])return false;     
                }
            return true;
            });
        //   setAlljobs(tempArr || []);
      }
      const handleClickJob = (row)=>{
        props.setCurJob(row)
      }
      function handleChangePage(event, newPage) {
        setPage(newPage);
      }
    
      function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
      }
      function handleAddsnackClose() {
        setAddsnack(false);
    
      }
      function handleAddsnackClick(){
        setAddsnack(true)
      }
      function handleBidNow(row){
        // console.log('hadndle Bid now')
        // console.log(props.user)
          if(!props.user)
             handleAddsnackClick();
        else{
            props.setCurJob(row)
            history.push("/jobs-details");
        }
    }
    //   const  isSelected = name => selected.indexOf(name) !== -1;
    
      const emptyRows = rowsPerPage - Math.min(rowsPerPage, alljobs.length - page * rowsPerPage);
    
    const classes = useStyles();
    useEffect(() => {
        if(props.user.active != 'Pending'){
            getlocations(props.user.zipCode );
            console.log(props.user.active,'status')
        }

        setAllcategory(props.allcategories || []);
        var tempArr = props.jobs;
        tempArr = tempArr.filter(sub=>{
          if(sub.status != 'In Progress')
              return false;
              if(props.user){
                var subTempArr = props.bids;
                    subTempArr = subTempArr.filter(sub1=>{
                            if(sub1.job?._id === sub?._id && sub1.nurse?._id === props.user?._id)
                            return true;
                            return false;
                        })
                    if(subTempArr[0])return false;     
            }
          return true;
          });
        // setAlljobs(tempArr || []);
      }, [props])

    return (
        <>
            <Header/>
            {/* <BreadCrumb title="Jobs" base="Home"/> */}
    
            
            <section className="apus-breadscrumb" style={{backgroundImage:`url(${bacImage})`}}>
                <div className="container">
                    <div className="wrapper-breads">
                        <div className="left-inner">
                            {/* <ol className="breadcrumb">
                                <li><a href="#">{this.props.base}</a>  </li> 
                                <li><i className="fas fa-angle-right"></i></li>
                                <li><span className="active">{this.props.title}</span></li>
                            </ol> */}
                        </div>
                        <div className="breadscrumb-inner clearfix">
                            <h2 className="bread-title">Jobs</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="jobs widget-filter-top" style={{backgroundColor:'white'}}>
                <div className="container">
                <FormGroup row>
                    <div className="col-lg-9 col-xs-12">
                        <div className="row">
                            <div className="col-md-4">
                                <TextField
                                    id="standard-bare"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    value={subText}
                                    onChange={(e)=>{setSubText(e.target.value);}}
                                    placeholder="e.g. talented job"
                                    inputProps={{ "aria-label": "bare" }}
                                />
                            </div>
                            <div className="col-md-4">
                                    <TextField
                                    id="outlined-select-currency"
                                    select
                                    placeholder="Category"
                                    className={classes.textField}
                                    value={filterCategory}
                                    onChange={(e)=>{setFilterCategory(e.target.value);}}
                                    SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem key='All Category' value='All Category'>
                                        Filter by Category
                                    </MenuItem>
                                    {allcategory.map(option => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </div>
    
                            <div className="col-md-4">
                            <TextField
                                    id="outlined-select-currency"
                                    select
                                    placeholder="Miles"
                                    className={classes.textField}
                                    value={filterMiles}
                                    onChange={(e)=>{setFilterMiles(e.target.value);}}
                                    SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem key='Miles' value='Miles'> Select Miles</MenuItem>
                                    <MenuItem key='1' value='25'>25 Miles</MenuItem>
                                    <MenuItem key='2' value="50">50 Miles</MenuItem>
                                    <MenuItem key='3' value='75'>75 Miles</MenuItem>
                                    <MenuItem key='4' value='100'>100 Miles</MenuItem>
                                    <MenuItem key='5' value='150'>150 Miles</MenuItem>
                                    <MenuItem key='6' value='200'>200 Miles</MenuItem>
                                    <MenuItem key='7' value='250'>250 Miles</MenuItem>  
                                    <MenuItem key='8' value='500'>500 Miles</MenuItem>
                                </TextField>
                            </div>
    
    
                            
                        </div>
                    </div>
                    <div className="col-lg-3 col-xs-12 margin-auto" >
                        <div className="flex-middle space-bottom-15">
                            <div className="visiable-line">
                            {/* filterBtn */}
                                <button className="button btn btn-theme-second" onClick={filterNurse}>Filter</button>
                            </div>
                            <a href="#" className="toggle-adv visiable-line btn button"onClick={() => {
                                        advanceflag? setAdvanceflag(false):setAdvanceflag(true);
                                        }}>
                                <i className="fas fa-cog"></i> 
                                Advance	
                            </a>
                        </div>
                    </div>
                    {advanceflag?<div className="row" style={{width:'100%'}}>
                            
                        {/* <div className="col-lg-4 col-xs-12 margin-auto" >
                            <Typography id="track-inverted-range-slider" gutterBottom>
                                        Review Rating
                                    </Typography>
                                    <Slider
                                        // track="inverted"
                                        aria-labelledby="track-inverted-range-slider"
                                        valueLabelFormat={valueLabelFormat1}
                                        valueLabelDisplay="auto"
                                        step={20}
                                        value={filterReview}
                                        onChange={(event,value) =>{setFilterReview(value)}}
                                            marks={marks1}
                                    />
                            
                        </div> */}
                        <div className="col-lg-4 col-xs-12 margin-auto" >
                            <Typography id="track-inverted-range-slider" gutterBottom>
                            Budget Range
                                    </Typography>
                                    <Slider
                                        // track="inverted"
                                        aria-labelledby="track-inverted-range-slider"
                                        valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={valuetext}
                                        valueLabelDisplay="auto"
                                            value={filterRangeBudget}
                                            onChange={(event,value) =>{setFilterRangeBudget(value)}}
                                        marks={marks}
                                    />
                            
                        </div>
                    </div>
                        :<div/>
                    }              
                </FormGroup>
                </div>
            </section>
            <section style={{backgroundColor:'white'}}>
                <div className="container jobSecond">
                    {selected?<div className="row">
                        <div className="results-filter-wrapper" style={{width:'100%'}}>
                        <h3 className="title">Your Selected</h3>
                        <div className="inner">
                            <ul className="results-filter">
                            {Object.keys(selected).map(key => 
                                    (key !== 'review' && key !== 'budget')?
                                <li><a onClick={(e)=>{removeOneSelect(key);}}><span className="close-value">x</span>{selected[key]}</a></li>
                                :(
                                key === 'review'?<li><a onClick={(e)=>{removeOneSelect('review');}}><span className="close-value">x</span>Review: {filterReview[0] / 20} ~ {filterReview[1] / 20}</a></li>
                                :<li><a onClick={(e)=>{removeOneSelect('budget');}}><span className="close-value">x</span>Budget: {filterRangeBudget[0]* 100}$ ~ {filterRangeBudget[1]* 100}$</a></li>)
                            )}
                                    {/* <li><a><span className="close-value">x</span>All</a></li> */}
                            </ul>
                            <a onClick={handleClearAll}>
                            <i className="fas fa-trash-alt"></i>
                            Clear all</a>
                            </div>
                        </div>
                    </div>:<div></div>
                    }
                    <div className="row">
                        <div className="jobs-alert-ordering-wrapper" style={{width:'100%'}}>
                            <div className="results-count">
                                Showing {page*rowsPerPage + 1} – {alljobs.length >= (page + 1)*rowsPerPage ?  (page + 1)*rowsPerPage : alljobs.length} of {alljobs.length} results
                            </div>
    
                        <div className="jobs-ordering-wrapper" style={{display:'flex'}}>
                
                                <TextField
                                        id="outlined-select-currency"
                                        select
                                        placeholder="Category"
                                        className={classes.textField1}
                                        value={rowsPerPage}
                                        onChange={(e)=>{setRowsPerPage(e.target.value); setPage(0)}}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem key='5 Per Pages' value={5}>
                                        5 Per Pages
                                        </MenuItem>
                                        <MenuItem key='10 Per Pages' value={10}>
                                        10 Per Pages
                                        </MenuItem>
                                    </TextField>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="jobs-wrapper items-wrapper">
                        {alljobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                    <div key={index}className="style-list-jobs">
                                        <article id="post-549" className="job-list map-item job-style-inner post-549 job_listing type-job_listing status-publish has-post-thumbnail hentry job_listing_type-internship job_listing_category-retail job_listing_location-new-york job_listing_tag-digital job_listing_tag-interviews job_listing_tag-jobs job_listing_tag-media" data-latitude="40.776629" data-longitude="-73.952531">
    
                                    <div className="flex-middle-sm job-list-container">
                                        <div className="candidate-logo candidate-thumbnail">
                                                <Link to='/jobs-details'onClick={()=>handleClickJob(row)}>
                                                <div className="image-wrapper image-loaded">
                                                    <img width="180" height="180" src={default_img} className="attachment-workio-logo-size size-workio-logo-size unveil-image" alt=""  sizes="(max-width: 180px) 100vw, 180px"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="job-information">
                                            <div className="title-wrapper">
                                                <h2 className="job-title">
                                                    <Link to='/jobs-details'onClick={()=>handleClickJob(row)}>
                                                        {row.title}
                                                    </Link>
                                                </h2>                
                                            </div>
                                            <div className="job-employer-info-wrapper">
                                                <h3 className="employer-title">
                                                        <Link to='/jobs-details'onClick={()=>handleClickJob(row)}>
                                                        {row.category.name}
                                                    </Link>
                                                </h3>
                                                <div className="job-salary">
                                                    $<span className="price-text">{row.budget}</span> 
                                                </div>    
                                                <div className="job-types">
                                                    <div className="job-type with-title">
                                                        <a className="type-job" href="#" style={{color: '#63ace5'}}>
                                                            {/* {row.summary} */}
                                                            ({row.budgetType})
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>            
                                        </div>
    
                                        <div className="deadline-time">Application ends: <strong>October 1, 2025</strong></div>
    
                                        <a className="btn btn-apply btn-apply-job-internal-required" onClick={()=>handleBidNow(row)}>Bid now<i className="next flaticon-right-arrow"></i></a>
                                        
                                        <Snackbar
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            open={addsnack}
                                            autoHideDuration={2000}
                                            onClose={handleAddsnackClose}
                                            ContentProps={{
                                            'aria-describedby': 'message-id',
                                            }}
                                            message={<span id="message-id">Please login firstly!</span>}
                                        />
                                        <div className="job-apply-internal-required-wrapper" style={{display:'none'}}>
                                            <div className="msg-inner">Please login with "Candidate" to apply</div>
                                        </div>
                                    </div>
                                </article>  
                            </div>
                                )})
                    }
                        </div>
                        {alljobs.length > rowsPerPage?
                        <div className="jobs-pagination-wrapper main-pagination-wrapper" style={{margin:'auto'}}>
                            <ul className="pagination">
                                {page >= 1 ?<li><a className="prev page-numbers"onClick={() => {setPage(page - 1);}}><i className="fas fa-chevron-left"></i></a></li>:<></>}
                                {page >= 1 ?<li><a className="page-numbers"onClick={() => {setPage(page - 1);}}>{page}</a></li>:<></>}
                                <li><span className="page-numbers current">{page + 1}</span></li>
                                {(page + 1)* rowsPerPage <= alljobs.length ?<li><a className="page-numbers"onClick={() => {setPage(page + 1);}}>{page + 2}</a></li>:<></>}
                                {(page + 1)* rowsPerPage <= alljobs.length ?<li><a className="next page-numbers"onClick={() => {setPage(page + 1);}}><i className="fas fa-chevron-right"></i></a></li>:<></>}
                            </ul>
                        </div>:<></>}
                    </div>
                </div>
            </section>
            <Footer/>
        </>
        );
    
  
}
const mapStateToProps = (state) => ({
    jobs: state.job.jobs,
    allcategories:state.category.allcategories,
    user:state.auth.user,
    bids:state.bid.bids
  })
  
  export default connect(
      mapStateToProps,
      {...authDuck.actions, ...activityDuck.actions,...jobDuck.actions, ...userDuck.actions, ...categoryDuck.actions, ...bidDuck.actions}
  )(Jobs);
  