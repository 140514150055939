import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../pages/home/MySnackBar';
import Avatar from '@material-ui/core/Avatar';
import { connect} from "react-redux";
import * as actions from '../../actions';
import * as userDuck from "../../store/ducks/user.duck";
import * as authDuck from '../../store/ducks/auth.duck';

import { Link } from 'react-router-dom';
import default_img from '../../assets/certificationlogo.png';
import Popup_modal_certificate from "../../pages/home/Popup_modal_certificate";
import Modal from 'react-modal';
import bacImage from '../../assets/jobset.jpeg';
import Header from "../../nurse/layout/Header";
import Footer from "../../nurse/layout/Footer";  
import urlserver from '../../config/urlserver';

const headRows = [
  { id: 'name', numeric: 'center', visibility:true,disablePadding: false, label: 'Notification ' },
  { id: 'date', numeric: 'center', visibility:true,disablePadding: false, label: 'Date' },
];
const mapStateToProps = (state) => ({
  user: state.auth.user,
})
  

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(0),

    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function NotiificationManagement(props) {
  const classes = useStyles();
  const [allinfo,setAllinfo] = React.useState([]);
  const [noData,setnoData] = React.useState('Loading...');


  useEffect(() => 
  {
    get_notifications()
  }, [])

  async function get_notifications(){
    await fetch(`${urlserver}notification-employee`,{
      method:'POST',
      headers:{
      'Content-Type': 'application/json' },
      body :JSON.stringify({ user_id: props.user._id})
      }
    ).then(res=>{
      res.json().then(data=>{
        if(data.notification.length == 0){
          setnoData("No Data Found")
        }
        var notificationss =  data.notification;
        notificationss.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.showDate) - new Date(a.showDate);
        });
        setAllinfo(notificationss)
        
      })

    }).catch(err=>{
        console.log('error:',err);
    })
  }



  return (
     <>
     <div className="table__notification">
        <Header/>
        <section className="apus-breadscrumb" style={{backgroundImage:`url(${bacImage})`}}>
            <div className="container">
                <div className="wrapper-breads">
                    <div className="left-inner">
                        {/* <ol className="breadcrumb">
                            <li><a href="#">{this.props.base}</a>  </li> 
                            <li><i className="fas fa-angle-right"></i></li>
                            <li><span className="active">{this.props.title}</span></li>
                        </ol> */}
                    </div>
                    <div className="breadscrumb-inner clearfix">
                        <h2 className="bread-title">Notifications</h2>
                    </div>
                </div>
            </div>
        </section>
        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
                <TableHead>
                  <TableRow>
                      {headRows.map((row,index) => (
                        <TableCell
                          key={row.id}
                          align={row.numeric}
                          padding={row.disablePadding ? 'none' : 'default'}
                          >
                            {row.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {((allinfo.length<=0)? <TableRow><TableCell colSpan={2} align="center">{noData}</TableCell></TableRow>:"")}

              { allinfo.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  var date_show = row.showDate;
                  if (typeof date_show !== "undefined") {
                    var objectDate2 = new Date(date_show);
                    date_show = objectDate2.toLocaleDateString() +' '+ objectDate2.toLocaleTimeString();
                  } else {
                    date_show = "";
                  }

                  var msg = '';
                  var type ='Added/Update';

                  if(row.type == "taskovertime"){
                    msg = `${row.nurse.firstName} ${row.nurse.lastName} ${type} over time to allocated time for task - ${row.task.title}`;
                  }else if(row.type == "tasktime") {
                    msg = `${row.nurse.firstName} ${row.nurse.lastName} ${type} time for task - ${row.task.title}`;
                  }else if(row.type == "bid") {
                    msg = `${row.nurse.firstName} ${row.nurse.lastName} bid on job - ${row.job.title}`;
                  }
                  
                  if(msg!=''){
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        
                        <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                          {msg}
                        </TableCell>
                        <TableCell align="center">{date_show}</TableCell>
                  
                      </TableRow>
                    );
                  }

                })}
            </TableBody>
            </Table>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default connect(
  mapStateToProps,
  {...userDuck.actions,...authDuck.actions}
)(NotiificationManagement)