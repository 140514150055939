import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import {
  TextField
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatIcon from '@material-ui/icons/Chat';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../../../pages/home/MySnackBar';
import Avatar from '@material-ui/core/Avatar';
import { connect} from "react-redux";
import * as actions from '../../../../actions';
import * as authDuck from "../../../../store/ducks/auth.duck";

import { green } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Config } from "../../../../config/config";
import FormGroup from '@material-ui/core/FormGroup';


const headRows = [
  { id: 'title', numeric: 'center', visibility:true,disablePadding: false, label: 'Title'},
  { id: 'summary', numeric: 'center', visibility:true,disablePadding: false, label: 'Summary'},
  { id: 'date_start', numeric: 'center', visibility:true,disablePadding: false, label: 'Start Date' },
  { id: 'date_end', numeric: 'center', visibility:true,disablePadding: false, label: 'End Date' },
  { id: 'total_time', numeric: 'center', visibility:true,disablePadding: false, label: 'Allocated Hour(s)' },
  { id: 'spent_time', numeric: 'center', visibility:true,disablePadding: false, label: 'Spent Hour(s)' },
  { id: 'status', numeric: 'center', visibility:true,disablePadding: false, label: 'Approve Status' },
];
const mapStateToProps = (state) => ({
  user:state.auth.user,
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(0),

    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function CurrentNurseWorking(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const jid = queryParams.get('jobId');
  const history = useHistory();
  const classes = useStyles();
  const { numSelected,selected } = props;
  const [allinfo,setAllinfo] = React.useState([]);
  const [task_id, setTaskId] = useState(false);
  const [SpentTime, setSpentTime] = useState(false);
  const [dialogopen,setDialogOpen] = React.useState(false)
  const [loading, setLoading] = useState(false);
  const [totalhrs, setTotalHrs] = useState(0);
  const [totalMins, setTotalMins] = useState(0);
  const [totalSec, setTotalSec] = useState(0);
  const [status, setStatus] = useState('');

  if(jid==='' || jid === 'null' || jid === null || jid === undefined){
    history.push("/myprofile/current-working-nurse");
  }
  const handleSpentTime = (event)=>{
    setSpentTime(event.target.value);
  }
  function handleDialogClose() {
    setTotalHrs(0)
    setTotalMins(0)
    setTotalSec(0)
    setStatus('')
    setDialogOpen(false);

  }
  useEffect(() => {
    fetchData();
  }, [])

  async function fetchData(){
    await getJobData()
    await get_tasks()
  }

  async function getJobData() {
    let response = await axios.post(`${Config.api_url}job/single-job`,{'jobId':jid}).then(res => { 
        
      if(res['data']['job']['nurse']!=props.user._id){
        history.push("/myprofile/current-working-nurse");
      }
    })
    return response;

  }

  async function get_tasks(){
    await fetch(`${Config.api_url}job/getTasks`,{
      method:'POST',
      headers:{
      'Content-Type': 'application/json' },
      body :JSON.stringify({ jobId: jid})
      }
    ).then(res=>{
      res.json().then(data=>{
        setAllinfo(data.tasks)
        
      })

    }).catch(err=>{
        console.log('error:',err);
    })
  }
  
  async function handleTime(id,status,jid){
    setSpentTime(0);
    setTaskId(0);
    if(status == 0){
      await fetch(`${Config.api_url}job/single-task`,{
        method:'POST',
        headers:{
        'Content-Type': 'application/json' },
        body :JSON.stringify({"job_id": jid,"_id":id})
        }
      ).then(res=>{
        res.json().then(data=>{
          setDialogOpen(true);        
          setTotalHrs(data.task.SpentHrs)
          setTotalMins(data.task.SpentMins)
          setTotalSec(data.task.SpentSec)
          setStatus('')
          setTaskId(id)
        })
  
      }).catch(err=>{
          console.log('error:',err);
      })
    }
  }


  async function handleTimeSave(job_id){
    if(totalMins ==0 && totalSec ==0 && totalhrs ==0 ){
      setStatus('Please add time')
      return;
    }

    if(totalMins > 59 || totalSec > 59 ){
      setStatus('Invalid Time Format')
      return;
    }
      await fetch(`${Config.api_url}job/addTaskTime`,{
        method:'POST',
        headers:{
        'Content-Type': 'application/json' },
        body :JSON.stringify({"job_id": job_id,"_id":task_id,"SpentHrs":totalhrs,"SpentMins":totalMins,"SpentSec":totalSec,"user_id":props.user._id})
        }
      ).then(res=>{
        res.json().then(data=>{
          setAllinfo(data.tasks)    
          setDialogOpen(false);        
        })
  
      }).catch(err=>{
          console.log('error:',err);
      })
    
  }

  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <TableHead>
                  <TableRow>
                      {headRows.map((row,index) => (
                        <TableCell
                          key={row.id}
                          align={row.numeric}
                          padding={row.disablePadding ? 'none' : 'default'}
                          >
                            {row.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {((allinfo.length<=0)? <TableRow><TableCell colSpan={7} align="center">No Data Found</TableCell></TableRow>:"")}
                { 
            
                  allinfo.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    var date_start = row.startDate;
                    if (typeof date_start !== "undefined") {
                      var objectDate2 = new Date(date_start);
                      var objectDate2Arr = objectDate2
                        .toLocaleDateString()
                        .split("/");

                        date_start =
                        objectDate2Arr[0] +
                        "/" +
                        objectDate2Arr[1] +
                        "/" +
                        objectDate2Arr[2];
                    } else {
                      date_start = "";
                    }


                    var date_end = row.endDate;
                    if (typeof date_end !== "undefined") {
                      var objectDate2 = new Date(date_end);
                      var objectDate2Arr = objectDate2
                        .toLocaleDateString()
                        .split("/");

                        date_end =
                        objectDate2Arr[0] +
                        "/" +
                        objectDate2Arr[1] +
                        "/" +
                        objectDate2Arr[2];
                    } else {
                      date_end = "";
                    }
                    
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          
                          <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                            {row.title}
                          </TableCell>
                          <TableCell align="center">{row.summary}</TableCell>
                          <TableCell align="center">{date_start}</TableCell>
                          <TableCell align="center">{date_end}</TableCell>
                          <TableCell align="center">
                            { prependNumber(row.TotalHrs)} H { prependNumber(row.TotalMins)} M { prependNumber(row.TotalSec)} S
                            </TableCell>
                          <TableCell align="center"> 
                              { prependNumber(row.SpentHrs)} H { prependNumber(row.SpentMins)} M { prependNumber(row.SpentSec)} S
                              {(row.status==0)?
                                  <Tooltip title="Add Time">
                                    <Link onClick={(e)=>{ e.preventDefault(); handleTime(row._id,row.status,jid) }} >
                                      <IconButton aria-label="AddCircleOutlineIcon">
                                        <AddCircleOutlineIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                              :''}
                              <Dialog
                              
                              open={dialogopen}
                              onClose={handleDialogClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">{"Task Spend Time"}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                <div className="row">
                                    <div className="form-group  row times">
                                      <div className="col-xl-3 col-lg-3">
                                        <div className="input-group">
                                          <input type="number" min="0" step="1" pattern="[0-9]" placeholder="Hours" className="form-control" value={totalhrs} onChange={ (e) => setTotalHrs(e.target.value) }/>
                                          <div className="input-group-append">Hours</div>
                                        </div>
                                      </div>
                                      <div className="col-xl-3 col-lg-3 ">
                                        <div className="input-group">
                                          <input type="number" min="0" max="59" step="1" pattern="[0-9]" placeholder="Mins" className="form-control" value={totalMins} onChange={ (e) => setTotalMins(e.target.value) }/>
                                          <div className="input-group-append">Mins</div>
                                        </div>
                                      </div>
                                      <div className="col-xl-3 col-lg-3">
                                        <div className="input-group">
                                          <input type="number" min="0" max="59" step="1" pattern="[0-9]" placeholder="Mins" className="form-control" value={totalSec} onChange={ (e) => setTotalSec(e.target.value) }/>
                                          <div className="input-group-append">Sec</div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <p className="TimeError">{status}</p>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleDialogClose} className="btn btn-primary">
                                  Cancel
                                </Button>
                                <Button style={{marginRight: 10, }}  onClick={(e)=>{ e.preventDefault(); handleTimeSave(jid) }}  className={`btn btn-primary pull-right ${clsx(
                                  {
                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                  }
                                )}`}>
                                  Submit
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                          <TableCell align="center"> 
                              <Button variant="contained" color={(row.status==1)?'secondary':'default'}>{row.status==1?(' Approved '):'Pending'}</Button>
                          </TableCell>
                        </TableRow>
                      );
                    

                  })


                }
               
                </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

function prependNumber(single_digit) {
 
  let prepended_out =
      String(single_digit).padStart(2, '0');
  return prepended_out;

}

export default connect(
  mapStateToProps,
  {...authDuck.actions}
)(CurrentNurseWorking)