import React, {useState, useEffect} from "react";
// , { useMemo }
import Desktop from "./Home/Desktop";
import Phone from "./Home/Phone";

import { connect } from 'react-redux';
// import { useSelector } from "react-redux";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import img1 from './../../assets/users/100_1.jpg';
import img2 from './../../assets/users/100_2.jpg';
import img3 from './../../assets/users/100_3.jpg';
import img4 from './../../assets/users/100_4.jpg';
import img5 from './../../assets/users/100_5.jpg';
import img6 from './../../assets/users/100_6.jpg';
import img7 from './../../assets/users/100_7.jpg';
import img8 from './../../assets/users/100_8.jpg';
import img9 from './../../assets/users/100_9.jpg';
import img10 from './../../assets/users/100_10.jpg';
import { animateScroll } from "react-scroll";
import './Messages.scss'
// import socket from './../../config/socket';
import * as messageDuck from '../../../app/store/ducks/message.duck';

// console.log('messages111',messageDuck);

function Messages(props) {
    // const [handle,setHandle] = useState('');
    // const [error,setError] = useState(null);
    const [sendStatus, setSendStatus] = useState([]);
//   const [messagesEnd,setMessagesEnd] = useState();
  const [flag, setFlag] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "chatContainer"
    });
}
window.onresize = () => {
    console.log(width);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (Math.abs(window.innerWidth - width) > 50) setWidth(window.innerWidth);
    if (Math.abs(window.innerHeight - height) > 50) setHeight(window.innerWidth);
  };
// socket.on('joined', (welcome_gift) => {
//     props.justJoined(welcome_gift.success);
// 	// store.dispatch(justJoined(welcome_gift.success));
// });

// socket.on('typing',(data) => {
//     console.log('-- sockert .on : typing ---')
//     props.isTyping(data);
// 	// store.dispatch(isTyping(data));
// });
// socket.on('addRoom',(data) => {
//     console.log('-- sockert .on : addRoom ---',data)
//     props.addRoom(data);
// 	// store.dispatch(isTyping(data));
// });
// socket.on('chat', (data) => {
//     console.log('-- sockert .on : chat ---')
//     console.log(data)
//     props.appendMessage(data);
// 	// store.dispatch(appendMessage(data));
// });
// socket.on('no_typing',(data) => {
//     console.log('-- sockert .on : no_typing ---')
//     props.notTyping(data);
// 	// store.dispatch(notTyping(data));
// });
    useEffect(() => {
        // alert('hiiii');
        window.onresize = () => {
            console.log(width);
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            if (Math.abs(window.innerWidth - width) > 50) setWidth(window.innerWidth);
            if (Math.abs(window.innerHeight - height) > 50) setHeight(window.innerWidth);
          };
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        // socket.emit('online');
    },[]);
    useEffect(() => {
        // shameful tricks for mobile
        window.onresize = () => {
          console.log(width);
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          if (Math.abs(window.innerWidth - width) > 50) setWidth(window.innerWidth);
          if (Math.abs(window.innerHeight - height) > 50) setHeight(window.innerWidth);
        };
      },[width]);
  const handleFlag=()=>{
    handleSend();
      if(flag === true)  
        setFlag(false);
    else
        setFlag(true);
    scrollToBottom();
  }
  const handleSend = () =>{
    handleSubmit('1','asdsad')
      var tempStatus = sendStatus;
      console.log('hadnleSend')
        console.log(tempStatus)
      tempStatus.push('1');
      console.log(tempStatus)

    setSendStatus(tempStatus);
  }
//   const handleTyping = () => {
    // socket.emit('typing',{
    //     'handle':handle,
    // });
// };

// const noMoreTyping = () => {
    // socket.emit('no_typing', {
    //     'handle':handle,
    // });
// };

const handleSubmit = (handle,message) => {

   
    if(handle === '' || message === '') {
        // setError('Handle or message should not be null');
        return;
    }
    // setError('');
        
    // socket.emit('chat',{
    //     'handle':handle,
    //     'message':message,
    // });
};

console.log('window',window.innerWidth);
let View = () => {
    // if (window.innerWidth > 1241) return <Desktop/>;
    // if (window.innerWidth > 991) return <Tablet/>;

    
    if (window.innerWidth > 991) return <Desktop/>;
    else return <Phone/>;
  };

  
   return(
    <>
    <Header/>
    <section className="msgSection">
    <View/>
    {/* <Desktop/> */}
    </section>
    </>
  );
}


const mapStateToProps = (state) => {
 
   
	const {messages,typist,joined} = state.message;

    // console.log('messages',messages);
    // console.log('typist',typist);
    // console.log('joined',joined);

	return {
		messages,
		typist,
		joined,
	};
};

console.log('stat11',Messages);
export default connect(mapStateToProps,{...messageDuck.actions})(Messages);
