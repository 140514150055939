import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
// import swal from 'sweetalert';
import { Alert } from "react-bootstrap";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import * as actions from '../../../../../app/actions';
import * as authDuck from '../../../../../app/store/ducks/auth.duck';
import * as categoryDuck from '../../../../../app/store/ducks/category.duck';
// import * as activityDuck from '../../../../app/store/ducks/activity.duck';
import * as userDuck from '../../../../../app/store/ducks/user.duck';
import * as bidDuck from '../../../../../app/store/ducks/bid.duck';

import {storage} from './../../../../../app/firebase';
import default_img from './../../../../assets/default_profile.png';
import { Link } from "react-router-dom";

function BidDetail(props) {
  const dispatch = useDispatch();
  const[bidBudget,setBudget] = useState(0);
  const[content,setContent] = useState('');
  const [status, setStatus] = useState('');
  const [id, setId] = useState('');
  const [avatar, setAvatar] = useState('');
  // const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [name, setFirst] = useState('');
  // const [location, setLocation] = useState('');
  // const [address, setAddress] = useState('');
  // const [phone, setPhone] = useState('');
  const [salary, setSalary] = useState(10);
  const [summary, setSummary] = useState('');
  const [category, setCategory] = useState('');
  const [categoryarr, setCategoryarr]=useState([]);
//   const [password, setPassword] = useState('');
  // const [uploadInput, setUploadInput] = useState(null);
  const [showImage, setShowImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const history = useHistory();
   const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  useEffect(() => {

    console.log('props.cursur')
    console.log(props)
    setBudget(props.curbid.bidBudget)
    setContent(props.curbid.content  )
    // setId(props.user._id || '');
    if(props.curbid.nurse != null){
      setTitle(props.curbid.nurse.title || '')
      setFirst(props.curbid.nurse.firstName + ' ' + props.curbid.nurse.lastName || '');
      setAvatar(props.curbid.nurse.profilePhoto || '');

    }
    // setEmail(props.user.email || '');
    // setLocation(props.user.location || '');
    // setAddress(props.user.address || '');
    // setPhone(props.user.phoneNumber || '');
    setSalary(props.user.salary || 1);
	setSummary(props.user.summary || '');
	if(props.role === 2){
		setCategoryarr(props.allcategories);
		if(props.user.category)
			setCategory(props.user.category || '');
		else
			setCategory(props.allcategories[0]._id);
	}

  }, [props])
  function handleChatNow(){
    dispatch({type: 'CREATE_ROOM', counterpart: props.curbid.job.client ||props.curbid.job.client._id })

  }
  const queryParams = new URLSearchParams(window.location.search);
  const job_id = queryParams.get('d');
  
  return (
	<div className="kt-grid__item kt-grid__item--fluid kt-app__content">
    <div className="row" style={{backgroundColor: "white"}}>
        <div className="col-md-12">
			<div className="kt-portlet">
				<div className="kt-portlet__head">
					<div className="kt-portlet__head-label">
						<h3 className="kt-portlet__head-title">Bid Detail Info</h3>
					</div>
				</div>
          {/* <span className="col-md-6 cold-sm-12" style={{fontSize: "1.275rem", fontWeight: 600 }}>Client Information</span> */}
          {/* <div className="col-md-6 col-sm-12 pull-right"> */}
		  		<div className="kt-form kt-form--label-right">
					<div className="kt-portlet__body">
						<div className="kt-section kt-section--first">
 							<div className="kt-section__body">
								 <div className ='row'>
									 {status !=='' ?<div className="col-md-12">
										<Alert variant='danger'>
											{status}
										</Alert>
									</div>:<div></div>}
								 </div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
									<div className="col-lg-9 col-xl-6">
										<div className="kt-avatar kt-avatar--outline" id="kt_user_avatar" style={{backgroundImage: showImage !== "" ? `url(${showImage})` : ( avatar === "" ? `url(${default_img})` : `url(${avatar})` ), backgroundSize: "cover"}}>
											<div className="kt-avatar__holder"></div>
                						
										</div>
									</div>
								</div>
								{props.role === 2 ?
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">Category</label>
									<div className="col-lg-9 col-xl-6">
										<select className="form-control" id="exampleSelectl" value={category} onChange={ (e) => setCategory(e.target.value) }>
										{categoryarr.map((row,index) => (
											<option key={row._id} value={row._id}>{row.name}</option>
										))}
										</select>
									</div>
								</div> : <></>}
                <div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">Budget</label>
									<div className="col-lg-9 col-xl-6">
									<input className="form-control" type="text" value={bidBudget} disabled />
									</div>
								</div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Cover letter</label>
                  <div className="col-lg-9 col-xl-6">
                    <textarea className="form-control"  rows="4"value={content} disabled></textarea>
                  </div>
								</div>
                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h3 className="kt-section__title kt-section__title-sm">Nurse Info:</h3>
                  </div>
                </div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">Title</label>
									<div className="col-lg-9 col-xl-6">
									<input className="form-control" type="text" value={title} disabled />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">Name</label>
									<div className="col-lg-9 col-xl-6">
									<input className="form-control" type="text" value={name} disabled/>
									</div>
								</div>
								<div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Summary</label>
                  <div className="col-lg-9 col-xl-6">
                    <textarea className="form-control"  rows="4"value={summary} disabled></textarea>
                  </div>
								</div>
								<div className="form-group  row">
									<label className="col-xl-3 col-lg-3 col-form-label">Salary</label>
									<div className="col-lg-9 col-xl-6">
									<div className="input-group">
										<input type="number" className="form-control" min="1" value={salary} disabled/>
										<div className="input-group-append"><span className="input-group-text">$</span></div>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="kt-portlet__foot">
						<div className="kt-form__actions">
							<div className="row">
 								<div className="col-lg-3 col-xl-3">
 								</div>
 								<div className="col-lg-9 col-xl-9">
									<Link to={'/myprofile/bid-list?d='+job_id}><button className="btn btn-secondary pull-right">Back</button></Link>
                  					<Link to="/messages"><button className="" style={{marginRight: 10}}>Chat now
									</button></Link>
										
								</div>
							</div>
						</div>
					</div>
				</div>
        </div>
      </div>
    </div>
	</div>
  );
}

const mapStateToProps = (state) => ({
  user:state.auth.user,
  role:state.auth.role,
  allcategories:state.category.allcategories,
  curbid:state.bid.curbid
})

export default connect(
    mapStateToProps,
    {...authDuck.actions, ...userDuck.actions,...categoryDuck.actions, ...bidDuck.actions}
)(BidDetail);
