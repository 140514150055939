import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../pages/home/MySnackBar';
import Avatar from '@material-ui/core/Avatar';
import { connect} from "react-redux";
import * as actions from '../../actions';
import * as userDuck from "../../store/ducks/user.duck";
import * as authDuck from '../../store/ducks/auth.duck';
import * as bidDuck from '../../store/ducks/bid.duck';
import * as jobDuck from '../../store/ducks/job.duck';
import { Link } from 'react-router-dom';
import default_img from '../../assets/certificationlogo.png';
import Popup_modal_certificate from "../../pages/home/Popup_modal_certificate";
import Modal from 'react-modal';
import bacImage from '../../assets/jobset.jpeg';
import Header from "../layout/Header";
import Footer from "../layout/Footer";  
import urlserver from '../../config/urlserver';

const headRows = [
  { id: 'name', numeric: 'center', visibility:true,disablePadding: false, label: 'Job ' },
  { id: 'client', numeric: 'center', visibility:true,disablePadding: false, label: 'Client ' },
  { id: 'status', numeric: 'center', visibility:true,disablePadding: false, label: 'Status ' },
  { id: 'date', numeric: 'center', visibility:true,disablePadding: false, label: 'Date' },
  { id: 'action', numeric: 'center', visibility:true,disablePadding: false, label: 'Action ' },

];
const mapStateToProps = (state) => ({
  user: state.auth.user,
  bids:state.bid.bids
})
  

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(0),

    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  rowHighLight: {
    backgroundColor:'#ffdcdc',
  }
}));


function NotiificationManagement(props) {
  const history = useHistory();

  const classes = useStyles();
  const [allinfo,setAllinfo] = React.useState([]);
  const [noData,setnoData] = React.useState('Loading...');
  const [tempBidArr,settempBidArr] = React.useState([]);
  const [addsnack, setAddsnack] = React.useState(false);


  function handleAddsnackClose() {
    setAddsnack(false);

  }
  function handleAddsnackClick(){
    setAddsnack(true)
  }
  function handleBidNow(row){
    // console.log('hadndle Bid now')
    // console.log(props.user)
      if(!props.user)
         handleAddsnackClick();
    else{
        props.setCurJob(row.job)
        history.push("/jobs-details");
    }
  }

  useEffect(() => 
  {

    let bids = props.bids;
    let bidsArr =[]
    if(bids.length > 0){
      bids.map((row, index) => {
        if(row.job!==undefined && row.job!==null){
          bidsArr.push(row.job._id);

        }

      });
    }
    

    settempBidArr(bidsArr)

    get_notifications()
  }, [props])

  async function get_notifications(){
    await fetch(`${urlserver}getInvitation`,{
      method:'POST',
      headers:{
      'Content-Type': 'application/json' },
      body :JSON.stringify({ user_id: props.user._id})
      }
    ).then(res=>{
      res.json().then(data=>{
        if(data.invitation.length == 0){
          setnoData("No Data Found")
        }
        var notificationss =  data.invitation;
        notificationss.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date) - new Date(a.date);
        });
        setAllinfo(notificationss)
        
      })

    }).catch(err=>{
        console.log('error:',err);
    })
  }



  return (
     <>
     <div className="table__notification">
        <Header/>
        <section className="apus-breadscrumb" style={{backgroundImage:`url(${bacImage})`}}>
            <div className="container">
                <div className="wrapper-breads">
                    <div className="left-inner">
                    </div>
                    <div className="breadscrumb-inner clearfix">
                        <h2 className="bread-title">Invitation</h2>
                    </div>
                </div>
            </div>
        </section>
        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
                <TableHead>
                  <TableRow>
                      {headRows.map((row,index) => (
                        <TableCell
                          key={row.id}
                          align={row.numeric}
                          padding={row.disablePadding ? 'none' : 'default'}
                          >
                            {row.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {((allinfo.length<=0)? <TableRow><TableCell colSpan={2} align="center">{noData}</TableCell></TableRow>:"")}
                        
              { allinfo.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // console.log((row.nurse,'row.row.');
                  var assign = 0;
                  if(row.job.nurse!==undefined && row.job.nurse!==null){
                    assign = 1;
                  }
                  
                  var date_show = row.date;
                  if (typeof date_show !== "undefined") {
                    var objectDate2 = new Date(date_show);
                    date_show = objectDate2.toLocaleDateString() +' '+ objectDate2.toLocaleTimeString();
                  } else {
                    date_show = "";
                  }

                  if(row.job!==undefined && row.job!==null){
                    if(tempBidArr.includes(row.job._id)){
                      row.status = 1;
                    }

                    if(assign == 1){
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}

                        >
                          
                      
                          <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                            {row.job.title}
                          </TableCell>
                          <TableCell align="center">{row.client.firstName +'  '+row.client.lastName } </TableCell>
                          <TableCell align="center">
                            <Button variant="outlined" color='primary'>
                              Job Assigned
                            </Button>
                          </TableCell>
                          <TableCell align="center">{date_show}</TableCell> 
                          <TableCell align="center"></TableCell>                                   
                        </TableRow>
                      );
                    }else{
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}

                        >
                          
                      
                          <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                            {row.job.title}
                          </TableCell>
                          <TableCell align="center">{row.client.firstName +'  '+row.client.lastName } </TableCell>
                          <TableCell align="center">
                            <Button variant="outlined" color={(row.status===0)?'default':'primary'}>
                              {(row.status===0)?'Bid Pending':'Bid Completed'}
                            </Button>
                          </TableCell>
                          <TableCell align="center">{date_show}</TableCell> 
                          <TableCell align="center">{(row.status===0)?<Link onClick={(e)=>{ e.preventDefault(); handleBidNow(row)} } >Bid Now</Link>:''}</TableCell>                                   
                        </TableRow>
                      );
                    }  
                  }

                })}
            </TableBody>
            </Table>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default connect(
  mapStateToProps,
  {...userDuck.actions,...authDuck.actions,...bidDuck.actions,...jobDuck.actions}
)(NotiificationManagement)