import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import {
  TextField
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatIcon from '@material-ui/icons/Chat';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from './../../../../pages/home/MySnackBar';
import Avatar from '@material-ui/core/Avatar';
import { connect} from "react-redux";
import * as actions from '../../../../actions';
import * as jobDuck from "../../../../store/ducks/job.duck";
import * as bidDuck from "../../../../store/ducks/bid.duck";
import * as authDuck from "../../../../store/ducks/auth.duck";
import * as userDuck from "../../../../store/ducks/user.duck";

import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import { Link } from 'react-router-dom';
import default_img from './../../../../assets/default_profile.png';
// import socket from './../../../../config/socket'
import * as messageDuck from '../../../../store/ducks/message.duck';
import RateReviewIcon from '@material-ui/icons/RateReview';
import Rating from '@material-ui/lab/Rating';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Box from '@material-ui/core/Box';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: '_id', numeric: 'center', visibility:false,disablePadding: true, label: 'Facility' },
  { id: 'title', numeric: 'center', visibility:true,disablePadding: false, label: 'Job Description' },
  { id: 'bidBudget', numeric: 'center', visibility:true,disablePadding: false, label: 'Budget' },
  { id: 'content', numeric: 'center', visibility:true,disablePadding: false, label: 'Content' },
  { id: 'status', numeric: 'center', visibility:true,disablePadding: false, label: 'Job Status' },
  { id: 'action', numeric: 'center', visibility:true,disablePadding: false, label: 'View' },

];
const mapStateToProps = (state) => ({
  bids: state.bid.bids,
  user:state.auth.user,
  curjob:state.job.curjob,
  rooms:state.message.rooms
})
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row,index) => (
          <TableCell
            key={row.id}
            align={row.numeric}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop:'0px',
    marginTop:'0px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected,selected } = props;
  const [addsnack, setAddsnack] = React.useState(false);
  const [multiremove,setMutliremove] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  function handleAddClick(){
    props.setCurBid({})

    // setAdd(true)
    
  }
  function handleMultiRemoveClick(){
    enableLoading();
    setTimeout(() => {
      actions.deleteBids(selected)
        .then(res => {
          disableLoading();
          let {data} = res;
          //console.log('===  delete category  == ')
          //console.log(res)
          if(!data.success) {
          }
          else{
            props.handleunSelect();
            setMutliremove(true);
            handleMultiRemoveClose();
            props.allBids(data.bids);
          }
        })
        .catch(() => {
        });
    }, 1000);
  }
  function handleMultiRemoveClose() {
    setMutliremove(false);
  }
  function handleAddsnackClose() {
    setAddsnack(false);

  }
  function handleAddsnackClick(){
    setAddsnack(true)
  }


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Awarded Jobs
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
          <Link to="/myprofile/overview"><IconButton aria-label="ArrowBackIcon" onClick={handleAddClick}>
              <ArrowBackIcon />
          </IconButton></Link>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected:PropTypes.array,
  allBids:PropTypes.func,
  handleunSelect:PropTypes.func,
  setCurBid:PropTypes.func
};
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(0),

    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function CurrentNurseWorking(props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [reviewSubject, setReviewSubject]= React.useState('');
  const [reviewWrite, setReviewWrite]= React.useState('');
  const [reviewvalueResponsiveness, setValueResponsiveness] = React.useState(0);
  const [reviewvalueProfessionalism, setValueProfessionalism] = React.useState(0);
  const [reviewvalueValue, setValueValue] = React.useState(0);
  const [reviewvalueFlexibility, setValueFlexibility] = React.useState(0);
  const [reviewvalueBehaviour, setValueBehaviour] = React.useState(0);
  const [clients, setClients]= React.useState([]);
  const [dialogopenAddTime,setDialogAddTimeOpen] = React.useState(false)
  const [dialogopenViewTime,setDialogViewTimeOpen] = React.useState(false)
  const [spenthour, setSpentHour]= React.useState('');
  
  function setSpentHourUpdate(event) {
    setSpentHour(event.target.value);
  }
  
  function handleApplyClickTime(row) {
    setDialogAddTimeOpen(true);
  }

  function handleApplyClickView(id) {
    history.push(`/myprofile/view-tasks?jobId=${id}`);
  }


  function handleDialogCloseTime() {
    setDialogAddTimeOpen(false);
  }

  function handleDialogCloseView() {
    setDialogViewTimeOpen(false);
  }
  
  const [state, setState] = React.useState({
    openState: false,
    vertical: 'bottom',
    horizontal: 'center',
    content:'successfully bid'
  });
  const history = useHistory();
  const [reviewdialogopen,setReviewDialogOpen] = React.useState(false)
  const [completeinfo, setCompleteInfo] = React.useState({});
  const { vertical, horizontal, openState,content } = state;

  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const [allBidinfo,setAllBidinfo] = React.useState([]);

  useEffect(() => {

    const fetchData = async()=> {
      await actions.getAllClient()
      .then(res => {
        let {data} = res;
        if(!data.success) {
          props.allClients([]);
        } else {
          props.allClients(data.clients);
          setClients(data.clients)
        }
        // console.log(data.clients);
      })
      .catch((err) => {
      });
    
      var arr = props.bids;
      var arrNew = [];
      await arr.map((item)=>{
          // console.log(item);
        if(item.job !== null){
            if(item.job.nurse === props.user._id){
                arrNew.push(item)
            }
         }
      })
      // console.log(arrNew);
     setAllBidinfo(arrNew);
    }
    fetchData()


  
  }, [props])
  
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [remove, setRemove] = React.useState(false);
  const [removesnack, setRemovesnack] = React.useState(false);
  const [curid,setCurid]= React.useState("");
  const [dialogopen,setDialogOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [awardinfo, setAwardInfo] = React.useState({});
  function handleApplyClick(row) {
    setDialogOpen(true);
    setAwardInfo(row)
  }
  function handleStateClose() {
    setState({ ...state, openState: false });
  }
  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleAward(){
    var tempData = {
      nurse: awardinfo.nurse._id,
      job:props.curjob._id
    }
    actions.setAward(tempData).then(res=>{
      let {data} = res;
      if(!data.success){
          setState({ ...state, openState: true, content:data.errMessage });
          handleDialogClose();
          return;
      }else{
      setState({ ...state, openState: true, content:'Successfully Awarded !!!' });
      props.allJobs(data.jobs);
      handleDialogClose();
      history.push("/myprofile/awarded-jobs");
      return;
      }
  }).catch(() => {
      setState({ ...state, openState: true, content: 'Error!!! you have to check your Database or Connection'});
      handleDialogClose();
  });
  }

  function handleEditClick(row){
    props.setCurBid(row)
  }
  function handleRemoveClose() {
    setRemove(false);
  }
  function handleRemoveClick(id){
    setRemove(true);
    setCurid(id);
  }
  function handleRemoveSnackClick(){
    enableLoading();
    setTimeout(() => {
      actions.deleteBid(curid)
        .then(res => {
          disableLoading();
          let {data} = res;
          if(!data.success) {
          }
          else{
            handleRemoveClose();
            setRemovesnack(true);
            props.allBids(data.bids);
          }
        })
        .catch(() => {
        });
    }, 1000);
  }
  function handleRemoveSnackClose(){
    setRemovesnack(false);
  }
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = allBidinfo.map(n => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }
  function handleReviewSubject(event) {
    setReviewSubject(event.target.value);
  }
  function handleReviewWrite(event) {
    setReviewWrite(event.target.value);
  }
  function handleunSelect(){
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }
  function handleReviewDialogClose() {
    setReviewDialogOpen(false);
  }
  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allBidinfo.length - page * rowsPerPage);
  function handleApplyReviewClick(row) {
    setReviewDialogOpen(true);
    setCompleteInfo(row)
    console.log('handleApplyReviewClick', row)
  }
  function handleReview(){
    if(reviewSubject === '' || reviewWrite === ''){
      alert('Please input Review Subject & Write')
      return;
    }
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      setTimeout(() => {
        var tempData ={
          Responsiveness: reviewvalueResponsiveness,  
            Professionalism: reviewvalueProfessionalism,
            Value: reviewvalueValue,
            Flexibility: reviewvalueFlexibility,
            Behaviour: reviewvalueBehaviour,
            reviewSubject: reviewSubject,
            reviewWrite: reviewWrite,
            reviewOverallRating: (reviewvalueResponsiveness + reviewvalueProfessionalism +reviewvalueValue + reviewvalueFlexibility + reviewvalueBehaviour) / 5,
            fromClient: null,
            fromNurse:props.user._id,
            toClient:completeinfo.job.client || completeinfo.job.client._id,
            toNurse:null,
            job:completeinfo.job._id
        }
        actions.giveReview(tempData).then(res =>{
          let {data} = res;
          if(!data.success){
            alert('Error!!!')
          }else{
          props.allJobs(data.jobs);
          props.allReviews(data.reviews);
          props.allBids(data.bids);
            alert('Successfully')
          }
          setSuccess(true);
          setLoading(false);
          setReviewDialogOpen(false);
        })
    

      }, 1000);
    }
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length}
        selected={selected} 
        allBids={props.allBids}
        handleunSelect={handleunSelect}
        setCurBid={props.setCurBid}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allBidinfo.length}
            />
            <TableBody>
              {stableSort(allBidinfo, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  var job_owner = '';
                  clients.map(( items, client ) => {

                      if(row.job.client == items._id){
                        job_owner =  items.firstName + ' ' +items.lastName;
                      }
                    });
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell  align="center" component="th" id={labelId} scope="row" padding="none">
                        {job_owner}
                      </TableCell>
                      <TableCell align="center">{row.job.title}</TableCell>

                      <TableCell align="center">{row.bidBudget}</TableCell>
                      <TableCell align="center">{row.content}</TableCell>
                      <TableCell align="center">
                        { (row.job !== null)?<Button variant="outlined" color={(row.job.status==='Pending' || row.status==='In Progress')?'primary':(row.job.status==='Completed'?'secondary':'default')}>{row.job.status}</Button>:'-' }
                      </TableCell>
                      <TableCell align="center">
                  
                          <Tooltip title="View Tasks">
                            <IconButton aria-label="CheckIcon"  onClick={()=>handleApplyClickView(row.job._id)} style={{paddingTop:0,paddingBottom:0}}>
                            <VisibilityIcon />
                            </IconButton> 
                          </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allBidinfo.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Delete Dialog */}
      <Dialog
        open={remove}
        onClose={handleRemoveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Alert?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this Bid?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleRemoveSnackClick} color="primary" autoFocus className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={removesnack}
        autoHideDuration={6000}
        onClose={handleRemoveSnackClose}
      >
        <MySnackbarContentWrapper
          onClose={handleRemoveSnackClose}
          variant={"success"}
          message="Removed Successfully!"
        />
      </Snackbar>
    </div>
  );
}

export default connect(
  mapStateToProps,
  {...authDuck.actions, ...jobDuck.actions, ...bidDuck.actions, ...messageDuck.actions,...userDuck.actions}
)(CurrentNurseWorking)